<template>
  <!-- eslint-disable -->
  <decorator-icon v-bind="attrs">
    <path clip-rule="evenodd"
          d="M30.5333 26.9333C31.0667 27.4667 31.7333 27.4667 32.2667 26.9333L39.6 19.6C39.8667 19.3333 40 18.9333 40 18.6667V18.5333C40 18.4 40 18.4 40 18.2667C40 18 39.8667 17.8667 39.7333 17.7333L32.4 10.4C31.8667 9.86667 31.2 9.86667 30.6667 10.4C30.1333 10.9333 30.1333 11.6 30.6667 12.1333L36 17.4667H17.8667C16.5333 17.4667 15.3333 17.7333 14.1333 18.2667C12.9333 18.6667 11.7333 19.3333 10.9333 20.2667C10 21.2 9.33333 22.2667 8.8 23.4667C8.26667 24.6667 8 26 8 27.2C8 29.8667 9.06667 32.2667 10.9333 34.1333C12.8 36 15.3333 37.0667 17.8667 37.0667H31.4667C32.1333 37.0667 32.6667 36.5333 32.6667 35.8667C32.6667 35.2 32.1333 34.6667 31.4667 34.6667H17.8667C15.8667 34.6667 14 33.8667 12.6667 32.5333C11.3333 31.2 10.5333 29.3333 10.5333 27.3333C10.5333 26.4 10.6667 25.4667 11.0667 24.5333C11.3333 23.4667 12 22.6667 12.6667 22C13.3333 21.3333 14.1333 20.8 15.0667 20.4C16 20 16.9333 19.8667 17.8667 19.8667H35.8667L30.5333 25.2C30 25.6 30 26.4 30.5333 26.9333Z"
          fill="inherit"
          fill-rule="evenodd"/>
  </decorator-icon>
</template>

<script>
import DecoratorIcon from '../decorator/dp-icon-decorator'
import MixinIcon from '../mixin/dp-icon-mixin'

/**
 * @group Components-icon-svg
 * Standard components
 */
export default {
  name: 'dp-redo-icon',
  mixins: [MixinIcon],
  components: {
    DecoratorIcon
  },
  data() {
    return {
      viewBox: '0 0 48 48'
    }
  }
}
</script>
